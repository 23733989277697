.custom {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 70px;
    color: black;
    background-color: white;
    text-align: center;
}
.med-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: black;
    background-color: white;
    text-align: left;
    padding: 4px;
    background-color: rgba(245, 222, 179, 0.73);
    border-radius: 20px;
}

.sm-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: large;
    font-weight: bold;
    color: black;
    background-color: white;
    text-align: center;
}

.footer-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: medium;
    width: 100%;
    background-color: #f8f9fa;
    text-align: right;
    position: fixed;
    bottom: 0;
    padding: 6px;
    opacity: 0.5;
}
html {
    scroll-behavior: smooth;
}

.cropped-gif {
    width: 90%;
    height: 40%;
    object-fit: cover;
}
.project-carousel {
    background: white;
}
  
.project-image {
    border: 5px solid white; /* Set the border color and width */
    max-height: 500px; /* Set the maximum height for the images */
    margin: auto; /* Center the image within the Carousel.Item */
}
 
.project-image {
    border: 10px solid transparent; /* Set the initial border width and make it transparent */
    max-height: 100%; /* Set the maximum height for the images */
    margin: auto; /* Center the image within the Carousel.Item */
}
  
.project-image:hover {
    border-image: linear-gradient(to right, transparent, white); /* Use a gradient for the fading border */
    border-image-slice: 1; /* Ensure the entire border is covered by the gradient */
    transition: border-image 0.5s ease; /* Add a transition for a smooth effect */
}

.home-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children along the cross-axis (horizontally) */
    height: 100vh; /* Set the height to 100% of the viewport */
}

.animation {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 100px;
    color: black;
    background-color: white;
    text-align: center;
}