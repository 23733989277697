p {
    font-size: medium;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    text-align: justify;
}

.greeter {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 70px;
    color: black;
    background-color: white;
    text-align: center;
}
::backdrop {
    background-color: grey;
}

html {
    scroll-behavior: smooth;
}